<template>
	<div>
		<v-breadcrumbs
			v-if="breadcrumb_categories.length > 1"
			:items="breadcrumb_categories"
			class="pt-0 pb-2 ps-4 greyDarken4--text"
		>
			<template v-slot:item="{ item }">
				<v-hover v-slot:default="{ hover }">
					<v-breadcrumbs-item
						@click="operateDiveIn(item)"
						:class="{
							'info--text cursor-wr': hover && !isDivingIntoSelf(item.id),
						}"
					>
						{{ $t(item.name) }}
					</v-breadcrumbs-item>
				</v-hover>
			</template>
			<template v-slot:divider>
				<v-icon>{{
					$vuetify.rtl
						? $vuetify.icons.values.left_chevron
						: $vuetify.icons.values.right_chevron
				}}</v-icon>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { diveInForAllCategories } from "@/mixins/diveInMixin";

export default {
	name: "ChooseThemeAltThemeDatabaseBreadCrumbs",

	mixins: [diveInForAllCategories],

	computed: {
		...mapState({
			breadcrumb_categories: (state) => state.category.breadcrumb_categories,
		}),
	},

	methods: {
		async operateDiveIn(category) {
			try {
				this.$loader.start();
				if (this.isDivingIntoSelf(category.id)) {
					return;
				}
				await this.diveIntoCategory(
					category,
					"pop_category_from_breadcrumbs",
					null,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
>>> .v-breadcrumbs li:nth-child(2n) {
	padding: 0 4px;
}
</style>
