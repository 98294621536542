<template>
	<v-row>
		<v-col sm="6" md="3" lg="3" v-for="(item, index) in items" :key="index">
			<v-hover v-slot:default="{ hover }">
				<v-card outlined tile class="pt-5" height="290">
					<v-container class="py-0" fill-height fluid v-if="!item.picture">
						<v-row justify="center">
							<v-col align="center">
								<v-img
									:src="require('@/assets/images/error.png')"
									height="80"
									contain
								>
								</v-img>
							</v-col>
						</v-row>
					</v-container>

					<v-img v-else :src="item.picture.url" height="200" contain>
						<template v-slot:placeholder>
							<ImageLoader></ImageLoader>
						</template>
					</v-img>

					<v-card-title
						v-text="item.name"
						class="subtitle-1 text-truncate font-weight-bold img-title-wr"
					></v-card-title>

					<!-- This popover will be displayed on hover -->
					<div
						v-if="hover"
						class="d-flex full-height-wr v-card--reveal elevation-5"
					>
						<PopOverMenu
							:item="item"
							:request_from="request_from"
							@display-more-info="displayMoreInfo(item.id)"
						></PopOverMenu>
					</div>
				</v-card>
			</v-hover>
		</v-col>

		<!-- Dialog box to get more info about content -->
		<ContentDialog
			v-if="more_info_dialog"
			v-model="more_info_dialog"
			:dialog="more_info_dialog"
			:category_id="selected_category_id"
			:request_from="request_from"
			@close="more_info_dialog = false"
		></ContentDialog>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import PopOverMenu from "@/components/research/modules/defineQuestion/stages/chooseTheme/alternatives/themeDatabase/PopOverMenu.vue";
import ContentDialog from "@/components/research/modules/defineQuestion/stages/chooseTheme/alternatives/themeDatabase/ContentDialog.vue";
import ImageLoader from "@/components/plugins/ImageLoader";

export default {
	name: "ChooseThemeAltThemeDatabaseContent",

	props: {
		items: {
			required: true,
			type: Array,
		},
		request_from: {
			required: true,
			type: String,
		},
	},

	data() {
		return {
			more_info_dialog: false,
			selected_category_id: null,
		};
	},

	components: {
		PopOverMenu,
		ContentDialog,
		ImageLoader,
	},

	methods: {
		displayMoreInfo(category_id) {
			try {
				this.selected_category_id = category_id;
				this.more_info_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.img-title-wr {
	position: absolute;
	bottom: 0;
	width: 100%;
}
.v-card--reveal {
	background: white;
	bottom: 0;
	position: absolute;
	width: 100%;
	border: 2px solid var(--v-amberDarken1-base) !important;
	transition: 0.9s ease-in-out;
}
</style>
