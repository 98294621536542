import { mapState } from "vuex";

export const manageFavoritesMixin = {
	data() {
		return {
			icon_width: 25,
			icon_height: 25,
		};
	},

	computed: {
		...mapState({
			favorites_categories: (state) => state.category.favorites,
			favorites_page: (state) => state.category.favorites_page,
			list_page: (state) => state.category.list_page,
			current_category: (state) => state.category.current_category,
		}),
	},

	methods: {
		async addToFavorites(category_id) {
			try {
				// Start loader
				this.$loader.start();
				// Send request
				await this.$store.dispatch("category/addToFavorites", {
					memo: `Adding new category ${category_id} to favorites.`,
					category: category_id,
				});
				// Mutate the updated data
				await this.mutateUpdatedData();
				// Stop loader
				this.$loader.stop();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async removeFromFavorites(category_id) {
			try {
				// Start loader
				this.$loader.start();
				/**
				 * If only one item is remaining and about to remove then
				 * decrement that page so previous page data can be loaded
				 */
				if (this.favorites_categories.results.length == 1) {
					this.$store.commit("category/DECREMENT_FAVORITES_PAGE");
				}
				// Send request
				await this.$store.dispatch("category/removeFromFavorites", {
					category_id: category_id,
				});
				await this.mutateUpdatedData();
				// Stop loader
				this.$loader.stop();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async mutateUpdatedData() {
			await this.$store.dispatch("category/getFavorites", {
				page: this.favorites_page,
			});
			this.diveIntoCategory(this.current_category, null, null);
		},
	},
};
