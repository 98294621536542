import { mapState } from "vuex";

export const diveInForAllCategories = {
	computed: {
		...mapState({
			list_page: (state) => state.category.list_page,
			current_category: (state) => state.category.current_category,
		}),
	},

	methods: {
		isDivingIntoAnother(category_id) {
			return category_id !== this.current_category.id;
		},

		isDivingIntoSelf(category_id) {
			return category_id == this.current_category.id;
		},

		isDivingIntoRoot(category_id) {
			return category_id == this.$defines.ROOT_CATEGORY_ID;
		},

		async diveIntoCategory(
			category,
			before_dive_in_operation,
			after_dive_in_operation,
			request_from_favorites = false,
		) {
			// If dive in request is coming from favorites section
			if (request_from_favorites) {
				await this.beforeDiveInFromFavorites(category.id);
			}
			// If diving into another then do some operation before diving in;
			if (this.isDivingIntoAnother(category.id)) {
				if (before_dive_in_operation) {
					await this.performDiveInOperation(before_dive_in_operation, category);
				}
				// Set param category to current breadcrumb category.
				await this.$store.commit("category/SET_CURRENT_CATEGORY", {
					id: category.id,
					name: category.name,
				});
				// Reset the list_page to one, because every new request should use page 1
				await this.$store.commit("category/RESET_LIST_PAGE");
			}
			// If diving into root category then get all categories and return
			if (this.isDivingIntoRoot(category.id)) {
				this.diveIntoRootCategory();
				return;
			}
			// If diving into another or self, this below code will same.
			let payload = {
				cat: category.id,
				name: category.name,
				page: this.list_page,
			};
			await this.$store.dispatch("category/getSubCategories", payload);
			// If any operation needed after dive-in then do here
			if (after_dive_in_operation) {
				this.performDiveInOperation(after_dive_in_operation, category);
			}
		},

		async beforeDiveInFromFavorites(category_id) {
			// if current category is not param category then reset breadcrumbs
			if (this.current_category.id !== category_id) {
				await this.$store.commit("category/RESET_BREADCRUMB_CATEGORIES");
			}
			// Redirect to first tab.
			await this.$eventBus.$emit("redirect-to-categories-tab");
		},

		async diveIntoRootCategory() {
			// Reset all other parent categories
			this.$store.commit("category/RESET_BREADCRUMB_CATEGORIES");
			// Send request to get all(root) categories
			await this.$store.dispatch("category/list", {
				page: this.list_page,
			});
		},

		performDiveInOperation(operation, category) {
			switch (operation) {
				case "push_category_to_breadcrumbs":
					// Push this category to breadcrumbs' array
					this.$store.commit(
						"category/PUSH_INTO_BREADCRUMB_CATEGORIES",
						category,
					);
					break;
				case "pop_category_from_breadcrumbs":
					this.$store.commit("category/POP_FROM_BREADCRUMB_CATEGORIES", {
						category_id: category.id,
					});
					break;
			}
		},
	},
};
