<template>
	<v-card flat class="wr_grey_1">
		<v-card flat class="wr_grey_1">
			<!-- This will be displayed when dive into subcategories -->
			<CategoriesCrumbs v-if="tab == 'list'"></CategoriesCrumbs>
			<v-tabs
				v-model="tab"
				background-color="transparent"
				slider-color="error"
				active-class="black--text font-weight-bold"
				ref="tabs"
			>
				<!-- First tab (All Categories) -->
				<v-tab href="#list" class="title text-truncate">
					{{ $t(current_category.name) }}
				</v-tab>

				<!-- Second tab (Favorites categories) -->
				<v-tab href="#favorites" class="title">
					<v-icon :left="!$vuetify.rtl" :right="$vuetify.rtl" :color="color"
						>$vuetify.icons.values.heart</v-icon
					>
					{{ $t("app.choose_theme.theme_database.my_fav.title") }}
					<span
						v-if="favorites_categories && favorites_categories.results.length"
						depressed
						class="ps-5 title error--text"
						>{{ favorites_categories.results.length }}</span
					>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item value="list">
					<v-card flat class="wr_grey_1">
						<v-card-text>
							<Content
								v-if="categories && categories.results.length"
								request_from="list"
								:items="categories.results"
							></Content>
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item value="favorites">
					<v-card flat class="wr_grey_1">
						<v-card-text>
							<Content
								v-if="
									favorites_categories && favorites_categories.results.length
								"
								request_from="favorites"
								:items="favorites_categories.results"
							></Content>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-card>

		<!-- Pagination for all and favorites categories -->
		<v-card flat tile class="pagination-container-wr">
			<v-pagination
				v-if="tab == 'list' && length"
				v-model="list_page"
				:length="length"
				color="amberDarken1"
				:total-visible="6"
			></v-pagination>

			<v-pagination
				v-if="tab == 'favorites' && favorites_length"
				v-model="favorites_page"
				:length="favorites_length"
				color="amberDarken1"
				:total-visible="6"
			></v-pagination>
		</v-card>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { loadClient } from "@/services/youtubeGapi";
import { paginationLength } from "@/utils/helpers";
import { diveInForAllCategories } from "@/mixins/diveInMixin";
import CategoriesCrumbs from "@/components/research/modules/defineQuestion/stages/chooseTheme/alternatives/themeDatabase/Breadcrumbs.vue";
import Content from "@/components/research/modules/defineQuestion/stages/chooseTheme/alternatives/themeDatabase/Content.vue";

export default {
	name: "ChooseThemeAltThemeDatabasePage",

	mixins: [diveInForAllCategories],

	data() {
		return {
			tab: "list",
		};
	},

	components: {
		CategoriesCrumbs,
		Content,
	},

	created() {
		this.readyToListenEvents();
	},

	watch: {
		list_page(newVal) {
			this.getCategories();
		},

		favorites_page(newVal) {
			this.getFavCategories();
		},

		"current_category.name": function() {
			// This function will change the slider's width every time when associated text change.
			this.$refs.tabs.callSlider();
		},
	},

	mounted() {
		this.init();
	},

	computed: {
		...mapState({
			categories: (state) => state.category.list,
			favorites_categories: (state) => state.category.favorites,
			current_category: (state) => state.category.current_category,
		}),

		...mapFields("category", {
			list_page: "list_page",
			favorites_page: "favorites_page",
			per_page_count: "per_page_count",
		}),

		color() {
			return this.tab == "favorites" ? "error" : "";
		},

		length() {
			return this.categories
				? paginationLength(this.categories.count, this.per_page_count)
				: 0;
		},

		favorites_length() {
			return this.favorites_categories
				? paginationLength(this.favorites_categories.count, this.per_page_count)
				: 0;
		},
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				await this.loadYouTubeClient();
				await this.getCategories();
				await this.getFavCategories();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async loadYouTubeClient() {
			// Load youtube client in web page
			await loadClient();
		},

		async getCategories() {
			try {
				this.$loader.start();
				await this.diveIntoCategory(
					this.current_category,
					null,
					"push_category_to_breadcrumbs",
				);
			} catch (error) {
				throw error;
			} finally {
				this.$loader.stop();
			}
		},

		async getFavCategories() {
			try {
				this.$loader.start();
				await this.$store.dispatch("category/getFavorites", {
					page: this.favorites_page,
				});
			} catch (error) {
				throw error;
			} finally {
				this.$loader.stop();
			}
		},

		readyToListenEvents() {
			this.$eventBus.$on("redirect-to-categories-tab", (payload) => {
				this.tab = "list";
			});
			this.$eventBus.$on("redirect-back", (payload) => {
				this.$router.push({
					name: "ChooseTheme",
				});
			});
			this.$eventBus.$on("redirect-next", (payload) => {
				this.$router.push({
					name: "ChooseThemeSummary",
				});
			});
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("redirect-to-categories-tab");
		this.$eventBus.$off("redirect-back");
		this.$eventBus.$off("redirect-next");
	},
};
</script>

<style scoped>
.pagination-container-wr {
	width: 95%;
}
</style>
