<template>
	<v-card v-if="item && Object.keys(item).length" width="100%">
		<v-card-title>
			<v-row no-gutters>
				<!-- Theme name -->
				<v-col
					sm="11"
					class="body-1 black--text font-weight-bold text-truncate"
					>{{ item.name }}</v-col
				>
				<!-- Add to favorites (If not added) -->
				<v-col sm="1">
					<v-tooltip
						:right="$vuetify.rtl"
						:left="!$vuetify.rtl"
						color="wr_indigo_2"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								color="error"
								@click="
									isAddedToFavorites
										? removeFromFavorites(item.id)
										: addToFavorites(item.id)
								"
								>{{
									$vuetify.icons.values[
										isAddedToFavorites ? "heart" : "heart_outline"
									]
								}}</v-icon
							>
						</template>
						<span>{{
							isAddedToFavorites
								? $t("app.remove_from_favorite")
								: $t("app.add_to_favorite")
						}}</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-card-title>

		<!-- Theme Description -->
		<v-card-text>
			{{ displayUptoLimit(item.description) }}
		</v-card-text>

		<!-- Actions -->
		<v-card-actions class="popover-actions-wr">
			<v-spacer></v-spacer>
			<v-chip
				rounded
				color="amberDarken1"
				class="px-5 py-0 cursor-wr"
				:disabled="!item.childrens"
				@click="$emit('display-more-info')"
				>{{ $t("app.read_view_media") }}
				<v-icon>{{
					$vuetify.icons.values[$vuetify.rtl ? "left_chevron" : "right_chevron"]
				}}</v-icon>
			</v-chip>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import { manageFavoritesMixin } from "@/mixins/manageFavoritesMixin";
import { diveInForAllCategories } from "@/mixins/diveInMixin";

export default {
	name: "ChooseThemeAltThemeDatabasePopOverMenu",

	mixins: [manageFavoritesMixin, diveInForAllCategories],

	data() {
		return {
			dialog: false,
		};
	},

	props: {
		item: {
			type: Object,
			required: true,
		},
		request_from: {
			type: String,
		},
	},

	computed: {
		isAddedToFavorites() {
			return this.$store.getters["category/isAddedToFavorites"](this.item);
		},
	},

	methods: {
		displayUptoLimit(text) {
			return text.substring(0, 230);
		},
	},
};
</script>

<style scoped>
.popover-actions-wr {
	position: absolute;
	bottom: 0;
	width: 100%;
}

>>> .v-icon.v-icon {
	font-size: 1.6875rem !important; /** 27px */
	vertical-align: unset !important;
}
</style>
