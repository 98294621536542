<template>
	<v-dialog
		:value="dialog"
		scrollable
		max-width="1000px"
		persistent
		v-if="category && Object.keys(category).length"
	>
		<v-card height="100%" class="pt-2">
			<div>
				<!-- Header -->
				<v-card-title>
					<v-row no-gutters>
						<v-col sm="6" class="black--text font-weight-bold ps-3">
							{{ category.name }}
						</v-col>

						<!-- Add to favorites (If not added) -->
						<v-col sm="1" align="center">
							<v-tooltip
								:right="$vuetify.rtl"
								:left="!$vuetify.rtl"
								color="wr_indigo_2"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										color="error"
										@click="
											operateFavorites(
												isAddedToFavorites ? 'remove' : 'add',
												category.id,
											)
										"
										>{{
											$vuetify.icons.values[
												isAddedToFavorites ? "heart" : "heart_outline"
											]
										}}</v-icon
									>
								</template>
								<span>{{
									isAddedToFavorites
										? $t("app.remove_from_favorite")
										: $t("app.add_to_favorite")
								}}</span>
							</v-tooltip>
						</v-col>

						<v-col sm="1">
							<TextToSpeech :text="category.description"></TextToSpeech>
						</v-col>

						<v-col sm="4" class="text-end">
							<v-icon
								large
								color="black"
								@click="doCloseDialog()"
								class="cursor-wr"
							>
								{{ $vuetify.icons.values.close }}
							</v-icon>
						</v-col>
					</v-row>
				</v-card-title>

				<!-- Information -->
				<v-card-text class="px-3">
					<!-- Theme Description -->
					<v-card-text class="subtitle-1">
						<read-more
							:more-str="$t('app.read_more')"
							:text="category.description"
							:less-str="$t('app.read_less')"
							:max-chars="400"
						></read-more>
					</v-card-text>

					<!-- Dive-In action -->
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-chip
							rounded
							color="amberDarken1"
							class="px-8 py-0 cursor-wr"
							:disabled="!category.childrens"
							@click="operateDiveIn(category)"
							>{{ $t("app.explore_more_themes") }}
							<v-icon>{{
								$vuetify.icons.values[
									$vuetify.rtl ? "left_chevron" : "right_chevron"
								]
							}}</v-icon>
						</v-chip>
					</v-card-actions>

					<!-- Video resources -->
					<v-divider class="my-2 mx-3 divider-wr"></v-divider>
					<v-card-text class="title black--text font-weight-bold pb-1">
						{{ $t("app.videos") }}
					</v-card-text>
					<v-card-text class="pt-0">
						<v-row v-if="videos && videos.length">
							<v-col
								sm="6"
								md="4"
								v-for="(video, index) in videos"
								:key="index"
							>
								<iframe
									:src="'https://www.youtube.com/embed/' + video.id"
									height="200px"
									frameborder="0"
									allowfullscreen
								/>
								<div class="greyDarken4--text">
									{{ video.title }}
								</div>
							</v-col>
						</v-row>
						<v-row v-else align="center" justify="center" class="py-5 title">
							{{ $t("app.searching_for_videos") }}
						</v-row>
					</v-card-text>

					<!-- Image Resources -->
					<v-divider class="my-2 mx-3 divider-wr"></v-divider>
					<v-card-text class="title black--text font-weight-bold pb-1">
						{{ $t("app.pictures") }}
					</v-card-text>
					<v-card-text class="pt-0">
						<v-row v-if="images.length">
							<v-col
								sm="6"
								md="4"
								v-for="(image, index) in images"
								:key="index"
							>
								<v-hover v-slot:default="{ hover }">
									<v-card flat tile class="image-container-wr cursor-wr">
										<v-img :src="image.url" height="200px" contain>
											<template v-slot:placeholder>
												<ImageLoader></ImageLoader>
											</template>
											<v-expand-transition>
												<div
													v-if="hover"
													class="d-flex transition-fast-in-fast-out full-height-wr v-card--reveal white--text"
												>
													<v-container fluid class="resource_info">
														<div class="subheading font-weight-bold">
															{{ image.name }}
														</div>
													</v-container>
												</div>
											</v-expand-transition>
										</v-img>
									</v-card>
								</v-hover>
							</v-col>
						</v-row>
						<div v-else align="center" justify="center">
							<v-img
								:src="require('@/assets/images/error.png')"
								width="80"
							></v-img>
						</div>
					</v-card-text>
				</v-card-text>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { isTypeImage } from "@/utils/helpers";
import { mapState } from "vuex";
import { manageFavoritesMixin } from "@/mixins/manageFavoritesMixin";
import { diveInForAllCategories } from "@/mixins/diveInMixin";
import { getVideoResources } from "@/services/youtubeGapi";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import ImageLoader from "@/components/plugins/ImageLoader";

export default {
	name: "ChooseThemeAltThemeDatabaseContentDialog",

	mixins: [manageFavoritesMixin, diveInForAllCategories],

	data() {
		return {
			images: [],
			videos: [],
		};
	},

	components: {
		TextToSpeech,
		ImageLoader,
	},

	props: {
		dialog: {
			type: Boolean,
			required: true,
		},
		category_id: {
			required: true,
		},
		// This prop is either coming from "Field's list" or "My Favorites"
		request_from: {
			type: String,
		},
	},

	mounted() {
		this.setUpCategory();
	},

	computed: {
		...mapState({
			category: (state) => state.category.category,
		}),
		isAddedToFavorites() {
			return this.$store.getters["category/isAddedToFavorites"](this.category);
		},
	},

	methods: {
		async setUpCategory() {
			await this.getCategory();
			await this.getCategoryMedia();
		},

		async getCategory() {
			try {
				this.$loader.start();

				await this.$store.dispatch(`category/get`, {
					category_id: this.category_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getCategoryMedia() {
			// Get Images
			await this.getImageResources();

			// Get Videos
			await this.getVideoResources();
		},

		getImageResources() {
			try {
				if (!this.category || !this.category.resources) return;

				this.category.resources.forEach((item) => {
					if (item.type.name == "picture" && isTypeImage(item.url)) {
						this.images.push(item);
					}
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async getVideoResources() {
			try {
				let response = await getVideoResources(this.category.name);

				if (response.items && response.items.length) {
					response.items.forEach((item) => {
						this.videos.push({
							title: item.snippet.title,
							id: item.id.videoId,
						});
					});
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async operateFavorites(operation, category_id) {
			try {
				operation == "add"
					? await this.addToFavorites(category_id)
					: await this.removeFromFavorites(category_id),
					// Mutate updated data
					this.getCategory();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async operateDiveIn(category) {
			try {
				this.$loader.start();
				// Close dialog box
				this.$emit("close");
				// Send dive-in request
				await this.diveIntoCategory(
					category,
					null,
					"push_category_to_breadcrumbs",
					this.request_from == "favorites",
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		doCloseDialog() {
			try {
				this.$loader.start();

				// Check if text-to-speech dialog is open then close it's voice.
				this.$eventBus.$emit("stop-tts");

				// Close parent dialog
				this.$emit("close");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$store.commit("category/RESET_SELECTED_CATEGORY");
	},
};
</script>

<style scoped>
.divider-wr {
	border: solid 1px lightgrey;
}
.v-card--reveal {
	background: var(--v-greyLighten2-base);
	bottom: 0;
	opacity: 0.9 !important;
	position: absolute;
	width: 100%;
}

.resource_info {
	word-break: break-all;
}

.image-container-wr {
	border: 1px solid var(--v-greyLighten2-base) !important;
}
</style>
