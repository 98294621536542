import { environments } from "@/environment/environment.js";

export const loadClient = () => {
	// Wait for gapi client obj to set
	window.gapi.load("client", async (_) => {
		// Now set API key using the client object
		await gapi.client.setApiKey(environments.youtube_api_key);

		// Now load the client
		return gapi.client
			.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
			.then(() => {
				console.log("GAPI client loaded for API");
			})
			.catch((error) => {
				throw error;
			});
	});
};

export const getVideoResources = async (keyword) => {
	// First check if client has loaded or not
	if (gapi.client && gapi.client.youtube) {
		// Send API request to get related resources from you tube
		let response = await gapi.client.youtube.search.list({
			part: ["snippet"],
			maxResults: 3,
			q: keyword.toLowerCase(),
		});

		return response.result;
	}
};
